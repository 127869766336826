import {
  faChevronUp,
  faFileLines,
  faFilePen,
  faFileSignature,
  faLockOpen,
  faSpinner,
  faUnlockKeyhole,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useEffect, useReducer, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Popconfirm } from "antd";
import SingleLectureCountDown from "./components/SingleLectureCountDown";
import VdoCiperrPlayer from "./vdoCipherComponents/VdoCiperrPlayer";
import useVdocipher from "./vdoCipherComponents/hooks/useVdocipher";
import { BunnyVideo } from "./components/BunnyVideo.jsx";
import YoutubeImage from "../../../assets/youtube.png";
import { useAuthState } from "../../../MainComponents/GlobalContext.jsx";

function StudentLecture() {
  const { lectureID } = useParams();
  const [videoRef, setVideoRef] = useState(null);
  const [code, setCode] = useState("");
  const videoContainerRef = useRef();
  const { loadVideo, isAPIReady } = useVdocipher();
  const AuthState = useAuthState();
  const [isCourse, setIsCourse] = useState();

  function reducer(state, action) {
    switch (action.type) {
      case "setCollapse": {
        return {
          ...state,
          collapse: action.payload,
        };
      }
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      case "setCurrentVideo": {
        return {
          ...state,
          currentVideo: action.payload,
        };
      }
      case "setPlatform": {
        return {
          ...state,
          platform: action.payload,
        };
      }
      case "setBuyLecture": {
        return {
          ...state,
          buyLecture: action.payload,
        };
      }
      case "setBuyLectureByPoints": {
        return {
          ...state,
          buyLectureByPoints: action.payload,
        };
      }
      case "setBuyLectureByAmount": {
        return {
          ...state,
          buyLectureByAmount: action.payload,
        };
      }
      case "setRefetch": {
        return {
          ...state,
          refetch: action.payload,
        };
      }
      case "setViews": {
        return {
          ...state,
          views: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    collapse: false,
    src: "",
    refetch: false,
    currentVideo: null,
    reviews: 0,
    buyLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByPoints: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByAmount: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!---- get lecture info -------
  const [LectureInfoSuccess, LectureInfoErrors, LectureInfoLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_LECTURE_INFO_API}${lectureID}`,
    "GET",
    lectureID,
    state.refetch
  );

  //!---- buy lecture  -------
  const [buyLectureSuccess, buyLectureErrors, submitLoading] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_LECTURE_API,
    "POST",
    state.buyLecture.flag,
    state.buyLecture.dependency,
    state.buyLecture.data,
    true
  );
  //!---- buy lecture By Points -------
  const [
    buyLectureByPointsSuccess,
    buyLectureByPointsErrors,
    buyLectureByPointsLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_BY_POINTS_API,
    "POST",
    state.buyLectureByPoints.flag,
    state.buyLectureByPoints.dependency,
    state.buyLectureByPoints.data,
    true
  );
  // !---- buy lecture By amount -------
  const [
    buyLectureByAmountSuccess,
    buyLectureByAmountErrors,
    buyLectureByAmountLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_BY_AMOUNT_API,
    "POST",
    state.buyLectureByAmount.flag,
    state.buyLectureByAmount.dependency,
    state.buyLectureByAmount.data,
    true
  );

  //!---refetch the lecture data again if the code is correct to open the paid content

  useEffect(() => {
    if (
      buyLectureSuccess ||
      buyLectureByPointsSuccess ||
      buyLectureByAmountSuccess
    ) {
      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });
      reset();
    }
  }, [buyLectureByPointsSuccess, buyLectureSuccess, buyLectureByAmountSuccess]);

  const {
    register,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const handleBuyLecture = (data) => {
    dispatch({
      type: "setBuyLecture",
      payload: {
        flag: "buyLecture",
        dependency: !state.buyLecture.dependency,
        data: { code, id: lectureID },
      },
    });
  };

  const handleBuyLectureByAmount = () => {
    dispatch({
      type: "setBuyLectureByAmount",
      payload: {
        flag: "buyLectureByAmount",
        dependency: !state.buyLectureByAmount.dependency,
        data: { id: lectureID },
      },
    });
  };

  if (LectureInfoLoading) {
    return <Loader />;
  }
  if (LectureInfoErrors && typeof LectureInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{LectureInfoErrors}</p>
      </div>
    );
  }

  const types = [
    "explanation",
    "homework",
    "quiz",
    "class-sheet",
    "summary",
    "essay",
    "session-work-shhet",
    "class-work-sheet",
  ];

  // booster image vidoe from iframge
  const getYouTubeVideoID = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?&]+)/;
    const match = url?.match(regex);
    return match ? match[1] : null;
  };

  const checkQuizHw = LectureInfoSuccess?.data?.session_history?.is_open === 1;

  return (
    <section
      className={`width min-h-screen   py-20 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | Nawar Academy"></HelmetTags>
      {/**
       * //!---- lecture-content and buying form -----
       */}
      <div className="lecture-top flex w-full justify-between gap-20 flex-col items-center ">
        {/**
         * //!------content-----
         */}
        <div
          dir="ltr"
          className={` flex flex-col w-full items-center text-center transition-{width} duration-500  ease-in-out md:w-full`}
        >
          <div
            className={`lecture-session-img-container  relative w-full overflow-hidden rounded-[25px] p-7  lg:w-full   `}
          >
            <img
              className="h-full w-full cursor-pointer rounded-[20px] object-cover duration-300 hover:saturate-150"
              src={LectureInfoSuccess?.data?.session_info?.img}
              alt={LectureInfoSuccess?.data?.session_info?.title}
            />
          </div>
          <div
            className={`title-description mt-8 rounded-2xl  p-5 w-3/4 text-center`}
          >
            <h1 className="lecture-session-title mb-9 text-4xl  font-bold">
              {LectureInfoSuccess?.data?.session_info?.title}
            </h1>
            <h2
              className="lecture-session-description text-3xl"
              dangerouslySetInnerHTML={{
                __html: LectureInfoSuccess?.data?.session_info?.description,
              }}
            />
          </div>
          {LectureInfoSuccess?.data?.session_info?.is_buy === 1 && (
            <>
              {LectureInfoSuccess?.data?.session_info.is_course !== 1 && (
                <SingleLectureCountDown
                  expireDate={
                    LectureInfoSuccess?.data?.session_history.expire_at
                  }
                  dispatch={dispatch}
                  state={state}
                />
              )}
            </>
          )}
        </div>
        {/**
         * //!------buying form-----
         */}
        {LectureInfoSuccess?.data?.session_info?.is_buy === 0 && (
          <div className="w-full flex flex-col gap-10 items-center">
            <div dir="ltr" className={`  flex  w-full justify-center`}>
              <div className="lecture-session-details-wrapper   flex h-auto w-full items-start overflow-hidden rounded-2xl border-2 border-secondary p-8  shadow-xl justify-between gap-14 md:flex-col-reverse md:items-center">
                <div className="playlist w-1/2  md:w-full">
                  <div
                    className={`toggle-playlist w-full overflow-hidden rounded-2xl border-2  border-secondary  ${
                      state.collapse
                        ? "h-[64px] bg-secondary text-light "
                        : "h-[465px]"
                    } transition-{height} duration-500 ease-in-out `}
                  >
                    <div
                      className="toggle-playlist  h-[64px] w-full cursor-pointer items-center justify-between gap-8 border-b-2 border-b-secondary  px-8 "
                      onClick={() =>
                        dispatch({
                          type: "setCollapse",
                          payload: !state.collapse,
                        })
                      }
                    >
                      <div
                        dir="ltr"
                        className="toggle-right font-semibold"
                      ></div>
                      <div className="toggle-left flex items-center justify-between gap-5 h-full">
                        <span className="text- font-semibold">
                          Lecture content
                        </span>
                        <FontAwesomeIcon
                          className={`${
                            state.collapse ? "rotate-180" : ""
                          } text-3xl transition-transform duration-500 ease-in-out`}
                          icon={faChevronUp}
                        />
                      </div>
                    </div>
                    <div
                      className={`playlist flex h-[400px] w-full flex-col gap-7 overflow-y-auto p-8 transition-opacity duration-500 ease-in-out ${
                        state.collapse ? "opacity-0 " : "opacity-100"
                      }`}
                    >
                      {types.map((type, index) => (
                        <>
                          {LectureInfoSuccess?.data?.videos.filter(
                            (video) => video.vdo_type === type
                          ).length !== 0 ||
                          LectureInfoSuccess?.data?.attachments?.filter(
                            (video) => video.attachment_type === type
                          ).length !== 0 ? (
                            <h3
                              className={`font-bold ${
                                index !== 0 &&
                                "pt-5 border-t-2 border-secondary"
                              }`}
                            >
                              {type.toUpperCase()}
                            </h3>
                          ) : null}

                          {LectureInfoSuccess?.data?.videos
                            .filter((video) => video.vdo_type === type)
                            ?.map((video, index) => {
                              let src =
                                video.platform === "youtube"
                                  ? `https://www.youtube.com/embed/${video.vdo_id}`
                                  : video.platform === "bunny"
                                    ? `https://iframe.mediadelivery.net/embed/234526/${video.vdo_id}`
                                    : video.platform === "vimeo"
                                      ? `https://player.vimeo.com/video/${video.vdo_id}`
                                      : `https://player.vdocipher.com/v2/?otp=${video.OTP}&playbackInfo=${video.playbackInfo}`;

                              return (
                                <div
                                  className={`single-video w-full  ${
                                    video.is_public === 1 ||
                                    LectureInfoSuccess?.data?.session_info
                                      ?.is_buy === 1
                                      ? "cursor-pointer "
                                      : " cursor-not-allowed"
                                  }`}
                                  key={video.key}
                                >
                                  <div
                                    onClick={() => {
                                      dispatch({
                                        type: "setCurrentVideo",
                                        payload: video.key,
                                      });
                                      dispatch({
                                        type: "setSrc",
                                        payload: src,
                                      });
                                      dispatch({
                                        type: "setPlatform",
                                        payload: video.platform,
                                      });

                                      // vdociper logic
                                      // videoContainerRef.current.innerHTML = "";

                                      // videoContainerRef.current.classList.add(
                                      //   "haveVideo"
                                      // );
                                      // const videoRef = loadVideo({
                                      //   otp: state.src,
                                      //   playbackInfo: state.src,
                                      //   container: state.src,
                                      // });
                                      // setVideoRef(videoRef);
                                    }}
                                    className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                                      video.is_public === 1 ||
                                      LectureInfoSuccess?.data?.session_info
                                        ?.is_buy === 1
                                        ? "hover:bg-secondary hover:text-white "
                                        : " pointer-events-none"
                                    }
                          ${
                            state.currentVideo === video.key &&
                            " bg-secondary text-white"
                          }`}
                                  >
                                    <div
                                      dir="ltr"
                                      className={`above flex w-full items-center justify-between gap-2`}
                                    >
                                      <div className="above-title w-session flex items-center justify-start gap-7">
                                        <img
                                          src={
                                            video.platform === "youtube"
                                              ? YoutubeImage
                                              : video.poster
                                          }
                                          className="w-[100px] h-40 object-cover rounded-md"
                                          alt=""
                                        />
                                        <div className="flex flex-col gap-2 flex-1">
                                          <p>
                                            {index + 1}. {video.name}
                                          </p>
                                          <p>{video.description}</p>

                                          {video.platform !== "youtube" && (
                                            <p>{video.duration}</p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="above-icon flex w-[20px] justify-end">
                                        <FontAwesomeIcon
                                          icon={
                                            video.is_public === 1 ||
                                            LectureInfoSuccess?.data
                                              ?.session_info?.is_buy === 1
                                              ? faLockOpen
                                              : faUnlockKeyhole
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {/**
                           * //!---- PDFs -----
                           */}
                          {LectureInfoSuccess?.data?.attachments
                            ?.filter((video) => video.attachment_type === type)
                            .map((attachment, index) => {
                              return (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                  href={
                                    attachment.is_public === 1 ||
                                    LectureInfoSuccess?.data?.session_info
                                      ?.is_buy === 1
                                      ? attachment.address
                                      : null
                                  }
                                  download={attachment.name}
                                  rel="noreferrer"
                                  target={
                                    attachment.is_public === 1 ||
                                    LectureInfoSuccess?.data?.session_info
                                      ?.is_buy === 1
                                      ? "_blank"
                                      : null
                                  }
                                  className={`single-attachment w-full  ${
                                    attachment.is_public === 1 ||
                                    LectureInfoSuccess?.data?.session_info
                                      ?.is_buy === 1
                                      ? "cursor-pointer "
                                      : " cursor-not-allowed"
                                  }`}
                                  key={attachment.key}
                                >
                                  <div
                                    className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                                      attachment.is_public === 1 ||
                                      LectureInfoSuccess?.data?.session_info
                                        ?.is_buy === 1
                                        ? ""
                                        : " pointer-events-none"
                                    }
                          hover:bg-secondary hover:text-white`}
                                  >
                                    <div
                                      dir="ltr"
                                      className={`above flex w-full items-center justify-between gap-2`}
                                    >
                                      <div className="above-title w-session flex items-center justify-start gap-7">
                                        <FontAwesomeIcon icon={faFileLines} />
                                        <p className="text- w-11/12 truncate hover:text-clip ">
                                          {index + 1}. {attachment.name}
                                        </p>
                                      </div>
                                      <div className="above-icon flex w-[20px] justify-end">
                                        <FontAwesomeIcon
                                          icon={
                                            attachment.is_public === 1 ||
                                            LectureInfoSuccess?.data
                                              ?.session_info?.is_buy === 1
                                              ? faLockOpen
                                              : faUnlockKeyhole
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              );
                            })}
                        </>
                      ))}
                    </div>
                  </div>
                </div>

                {AuthState.userData.student.active !== 0 && (
                  <>
                    {LectureInfoSuccess?.data?.session_info?.seq === 0 &&
                    LectureInfoSuccess?.data?.session_info?.exceptions === 0 ? (
                      <p className="w-1/2 md:w-full  inset-0 flex h-full flex-col items-center justify-center font-bold text-3xl">
                        You Must Buy This Lecture First
                        <span>
                          {LectureInfoSuccess?.data?.session_info?.req_lecture}
                        </span>
                      </p>
                    ) : (
                      <>
                        {LectureInfoSuccess?.data?.session_info?.price !== 0 ? (
                          <>
                            {LectureInfoSuccess?.data?.session_info
                              ?.enable_buy +
                              LectureInfoSuccess?.data?.session_info
                                ?.permissions !==
                            0 ? (
                              <div className="form w-1/2 md:w-full  inset-0 flex h-full  items-center justify-center gap-6 p-5">
                                <div className="flex  w-[300px] flex-col items-center gap-8 ">
                                  <h3 className="price  my-5 text-center text-3xl font-bold">
                                    <span>
                                      {" "}
                                      {
                                        LectureInfoSuccess?.data?.session_info
                                          ?.price
                                      }
                                    </span>{" "}
                                    <span>EGP</span>
                                  </h3>
                                  <input
                                    className="code-input signin-inputs signin-input h-16 rounded-lg pl-7"
                                    placeholder="Add your code"
                                    type="text"
                                    name="code"
                                    // value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                  />

                                  {
                                    //!-------server errors -----

                                    buyLectureErrors &&
                                      buyLectureErrors?.response?.data?.errors
                                        ?.code && (
                                        <p className="w-full  text-[12px] text-blue-900  ">
                                          {
                                            buyLectureErrors?.response?.data
                                              ?.errors?.code[0]
                                          }
                                        </p>
                                      )
                                  }
                                  {/** submit */}

                                  <Popconfirm
                                    okText="Confirm"
                                    okType="danger"
                                    cancelText="Cancel"
                                    color="#fecaca"
                                    //okButtonProps={{ loading: state.loading }}
                                    onConfirm={handleBuyLecture}
                                    dir="rtl"
                                    title={
                                      <p
                                        className="text-end font-bold"
                                        dir="rtl"
                                      >
                                        انت متأكد انك عاوز تفتح سيشين{" "}
                                        {
                                          LectureInfoSuccess?.data?.session_info
                                            ?.title
                                        }{" "}
                                        للعلم لا يمكن تبديلها لو دوست موافق
                                        {""}؟
                                      </p>
                                    }
                                  >
                                    <button
                                      disabled={
                                        !isValid ||
                                        (submitLoading &&
                                          state.buyLecture.flag) ||
                                        buyLectureByAmountLoading
                                      }
                                      className=" submit mt-6 w-full "
                                      type="submit"
                                    >
                                      {submitLoading &&
                                      state.buyLecture.flag ? (
                                        <FontAwesomeIcon
                                          icon={faSpinner}
                                          spin
                                        />
                                      ) : (
                                        "Buy the lecture"
                                      )}
                                    </button>
                                  </Popconfirm>
                                  {LectureInfoSuccess?.data?.session_info
                                    ?.amount > 0 && (
                                    <Popconfirm
                                      okText="Confirm"
                                      okType="danger"
                                      cancelText="Cancel"
                                      color="#fecaca"
                                      //okButtonProps={{ loading: state.loading }}
                                      onConfirm={handleBuyLectureByAmount}
                                      title="Confirm Buy This Lecture?"
                                    >
                                      <button
                                        disabled={
                                          !isValid ||
                                          (submitLoading &&
                                            state.buyLecture.flag) ||
                                          buyLectureByAmountLoading
                                        }
                                        className=" submit mt-6 w-full "
                                        type="button"
                                      >
                                        {submitLoading &&
                                        state.buyLecture.flag ? (
                                          <FontAwesomeIcon
                                            icon={faSpinner}
                                            spin
                                          />
                                        ) : (
                                          "Buy the Balance"
                                        )}
                                      </button>
                                    </Popconfirm>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <p className="w-1/2 md:w-full text-center  inset-0 flex h-full items-center justify-center font-bold text-3xl">
                                {AuthState.userData.student.is_online === 1 && (
                                  <span>
                                    تم رفع الحصة من السايت <br />
                                    بس في عندك فرصتين فقط تشتري حصة اترفعت من
                                    السايت اتواصل علي بيدج نوار اكاديمي علي
                                    الفيس بوك عشان يساعدوك تشتريها ✅
                                  </span>
                                )}
                                {AuthState.userData.student.is_online === 0 && (
                                  <span>
                                    لانك طالب سنتر في عندك فرصتين فقط تشتري حصة
                                    اونلاين اتواصل علي بيدج نوار اكاديمي علي
                                    الفيس بوك عشان يساعدوك تشتريها ✅
                                  </span>
                                )}
                              </p>
                            )}
                          </>
                        ) : (
                          <p className="w-1/2 md:w-full  inset-0 flex h-full items-center justify-center font-bold text-3xl">
                            Free Lecture
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="w-2/3 md:w-full">
              <div
                className={`
              flex video  width justify-end`}
              >
                <div
                  className={`iframe-wrapper aspect-video overflow-hidden rounded-2xl  ${
                    state.currentVideo !== null && "border-secondary border-2 "
                  } w-full`}
                >
                  <VdoCiperrPlayer
                    videoId={state.currentVideo}
                    platform={state.platform}
                    // views={state.views}
                    history_id={LectureInfoSuccess?.data?.session_history?.key}
                    videoRef={videoRef}
                    videoContainerRef={videoContainerRef}
                    isAPIReady={isAPIReady}
                    setVideoRef={setVideoRef}
                  />
                  {state.platform !== "vdocipher" &&
                  state.platform !== "bunny" ? (
                    <iframe
                      src={state.src}
                      width="100%"
                      height="100%"
                      title="YouTube video player"
                      //style={{ aspectRatio: "16 / 9" }}
                      allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      id="courseVideo"
                    ></iframe>
                  ) : null}

                  {state.platform === "bunny" && <BunnyVideo src={state.src} />}
                </div>
              </div>
              {LectureInfoSuccess?.data?.session_info.is_course !== 1 ? (
                <>
                  {state.views && (
                    <p className="font-bold text-3xl mt-12 text-center">
                      {state.views === 0
                        ? "You have exceeded the viewing limit"
                        : `views: ${state.views}`}
                    </p>
                  )}
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {/**
       * //!---- lecture homework  quiz & PDFs -----
       */}

      {LectureInfoSuccess?.data?.session_history?.method !== "center" && (
        <>
          {LectureInfoSuccess?.data?.session_info?.is_buy === 1 &&
            (LectureInfoSuccess?.data?.session_history?.is_hw === 1 ||
              LectureInfoSuccess?.data?.session_history?.is_quiz === 1) && (
              <>
                {(LectureInfoSuccess?.data?.session_history?.is_hw_result ===
                  0 &&
                  LectureInfoSuccess?.data?.session_history?.homework_done ===
                    1) ||
                (LectureInfoSuccess?.data?.session_history?.is_quiz_result ===
                  0 &&
                  LectureInfoSuccess?.data?.session_history?.quiz_done ===
                    1) ? null : (
                  <section
                    className={`${
                      LectureInfoSuccess?.data?.session_history?.method ===
                      "center"
                        ? "hidden"
                        : ""
                    } mt-24 flex w-full items-start justify-center gap-4 rounded-2xl border-2 border-secondary py-5 dark:bg-dark dark:text-light md:flex-col-reverse md:items-center md:justify-start`}
                  >
                    {/**
                     * //!---- homework -----
                     */}
                    {LectureInfoSuccess?.data?.session_history?.is_hw === 1 && (
                      <>
                        {LectureInfoSuccess?.data?.session_history
                          ?.is_hw_result === 0 &&
                        LectureInfoSuccess?.data?.session_history
                          ?.homework_done === 1 ? null : (
                          <div className="homework h-96 w-1/3 p-8 py-4 md:w-full md:pt-10">
                            <div className="mb-12 flex justify-between">
                              {LectureInfoSuccess?.data?.session_history
                                ?.is_hw_result === 1 ? (
                                <Link
                                  to={`/lectures/sessions/modal-answer/${lectureID}`}
                                  className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                                >
                                  Model Answer
                                </Link>
                              ) : (
                                <Link
                                  to={`/home/homework/${lectureID}`}
                                  className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                                >
                                  Start the homework
                                </Link>
                              )}

                              <h2 className=" w-fit  text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
                                <span className="mr-4">Homework</span>
                                <FontAwesomeIcon icon={faFilePen} />
                              </h2>
                            </div>

                            {LectureInfoSuccess?.data?.session_history
                              ?.is_hw_result === 1 && (
                              <div className="student-quiz-info flex w-full flex-col gap-10">
                                <h2 className="flex w-full items-center justify-between ">
                                  <span>
                                    {
                                      LectureInfoSuccess?.data?.session_history
                                        .hw_degree
                                    }
                                  </span>
                                  <span className="font-semibold">Degree</span>
                                </h2>
                                <h2 className="flex w-full items-center justify-between ">
                                  <span>
                                    {
                                      LectureInfoSuccess?.data?.session_history
                                        .averagehw
                                    }
                                  </span>
                                  <span className="font-semibold">
                                    Avarage Degrees
                                  </span>
                                </h2>
                                <h2 className="flex w-full items-center justify-between ">
                                  <span>
                                    {
                                      LectureInfoSuccess?.data?.session_history
                                        .rankhw
                                    }
                                  </span>
                                  <span className="font-semibold">
                                    Student Rank in the lecture
                                  </span>
                                </h2>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {/**
                     * //!---- quiz -----
                     */}
                    {LectureInfoSuccess?.data?.session_history?.is_quiz ===
                      1 && (
                      <>
                        {LectureInfoSuccess?.data?.session_history
                          ?.is_quiz_result === 0 &&
                        LectureInfoSuccess?.data?.session_history?.quiz_done ===
                          1 ? null : (
                          <div className="quiz h-96 w-1/3 p-8 py-4 md:w-full md:border-r-0 md:pt-10">
                            <div className="mb-12 flex justify-between">
                              {LectureInfoSuccess?.data?.session_history
                                ?.is_quiz_result === 1 ? (
                                <Link
                                  to={`/lectures/sessions/modal-answer/${lectureID}`}
                                  className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                                >
                                  Model Answer
                                </Link>
                              ) : (
                                <Link
                                  to={`/home/quiz/${lectureID}`}
                                  className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                                >
                                  Start the Quiz
                                </Link>
                              )}

                              <h2 className=" w-fit  text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
                                <span className="mr-4">Quiz</span>
                                <FontAwesomeIcon icon={faFileSignature} />
                              </h2>
                            </div>

                            {LectureInfoSuccess?.data?.session_history
                              ?.is_quiz_result === 1 && (
                              <div className="student-quiz-info flex w-full flex-col gap-10">
                                <h2 className="flex w-full items-center justify-between ">
                                  <span>
                                    {
                                      LectureInfoSuccess?.data?.session_history
                                        .quiz_degree
                                    }
                                  </span>
                                  <span className="font-semibold">Degree</span>
                                </h2>
                                <h2 className="flex w-full items-center justify-between ">
                                  <span>
                                    {
                                      LectureInfoSuccess?.data?.session_history
                                        .averageqz
                                    }
                                  </span>
                                  <span className="font-semibold">
                                    Avarage Degrees
                                  </span>
                                </h2>
                                <h2 className="flex w-full items-center justify-between ">
                                  <span>
                                    {
                                      LectureInfoSuccess?.data?.session_history
                                        .rankqz
                                    }
                                  </span>
                                  <span className="font-semibold">
                                    Student Rank in the lecture
                                  </span>
                                </h2>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </section>
                )}
              </>
            )}
        </>
      )}

      {checkQuizHw ? (
        <div className="lecture-bottom video-playlist  mt-24 flex w-full items-start justify-between gap-24 lg:gap-16 md:flex-col-reverse md:items-center">
          {LectureInfoSuccess?.data?.session_info?.is_buy !== 0 && (
            <div className="playlist w-1/2  md:w-full">
              <div
                className={`toggle-playlist w-full overflow-hidden rounded-2xl border-2  border-secondary  ${
                  state.collapse
                    ? "h-[64px] bg-secondary text-light "
                    : "h-[465px]"
                } transition-{height} duration-500 ease-in-out `}
              >
                <div
                  className="toggle-playlist  h-[64px] w-full cursor-pointer items-center justify-between gap-8 border-b-2 border-b-secondary  px-8 "
                  onClick={() =>
                    dispatch({
                      type: "setCollapse",
                      payload: !state.collapse,
                    })
                  }
                >
                  <div dir="ltr" className="toggle-right font-semibold"></div>
                  <div className="toggle-left flex items-center justify-between gap-5 h-full">
                    <span className="text- font-semibold">Lecture content</span>
                    <FontAwesomeIcon
                      className={`${
                        state.collapse ? "rotate-180" : ""
                      } text-3xl transition-transform duration-500 ease-in-out`}
                      icon={faChevronUp}
                    />
                  </div>
                </div>
                <div
                  className={`playlist flex h-[400px] w-full flex-col gap-7 overflow-y-auto p-8 transition-opacity duration-500 ease-in-out ${
                    state.collapse ? "opacity-0 " : "opacity-100"
                  }`}
                >
                  {types.map((type, index) => (
                    <>
                      {LectureInfoSuccess?.data?.videos.filter(
                        (video) => video.vdo_type === type
                      ).length !== 0 ||
                      LectureInfoSuccess?.data?.attachments?.filter(
                        (video) => video.attachment_type === type
                      ).length !== 0 ? (
                        <h3
                          className={`font-bold ${
                            index !== 0 && "pt-5 border-t-2 border-secondary"
                          }`}
                        >
                          {type.toUpperCase()}
                        </h3>
                      ) : null}
                      {LectureInfoSuccess?.data?.videos
                        ?.filter((video) => video.vdo_type === type)
                        .map((video, index) => {
                          let src =
                            video.platform === "youtube"
                              ? `https://www.youtube.com/embed/${video.vdo_id}`
                              : video.platform === "bunny"
                                ? `https://iframe.mediadelivery.net/embed/234526/${video.vdo_id}`
                                : video.platform === "vimeo"
                                  ? `https://player.vimeo.com/video/${video.vdo_id}`
                                  : `https://player.vdocipher.com/v2/?otp=${video.OTP}&playbackInfo=${video.playbackInfo}`;

                          return (
                            <div
                              className={`single-video w-full  ${
                                video.is_public === 1 ||
                                LectureInfoSuccess?.data?.session_info
                                  ?.is_buy === 1
                                  ? "cursor-pointer "
                                  : " cursor-not-allowed"
                              }`}
                              key={video.key}
                            >
                              <div
                                onClick={() => {
                                  dispatch({
                                    type: "setCurrentVideo",
                                    payload: video.key,
                                  });
                                  dispatch({
                                    type: "setSrc",
                                    payload: src,
                                  });
                                  dispatch({
                                    type: "setPlatform",
                                    payload: video.platform,
                                  });
                                  dispatch({
                                    type: "setViews",
                                    payload: video.views,
                                  });
                                  setIsCourse(video.is_course);

                                  // vdociper logic
                                  videoContainerRef.current.innerHTML = "";

                                  videoContainerRef.current.classList.add(
                                    "haveVideo"
                                  );
                                  const videoRef = loadVideo({
                                    otp: video.OTP,
                                    playbackInfo: video.playbackInfo,
                                    container: videoContainerRef.current,
                                    configuration: {
                                      // autoplay: true
                                    },
                                  });
                                  setVideoRef(videoRef);
                                }}
                                className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                                  video.is_public === 1 ||
                                  LectureInfoSuccess?.data?.session_info
                                    ?.is_buy === 1
                                    ? "hover:bg-secondary hover:text-white "
                                    : " pointer-events-none"
                                }
                          ${
                            state.currentVideo === video.key &&
                            " bg-secondary text-white"
                          }`}
                              >
                                <div
                                  dir="ltr"
                                  className={`above flex w-full items-center justify-between gap-2`}
                                >
                                  <div className="above-title w-session flex items-center justify-start gap-7">
                                    <img
                                      // src={video.poster}
                                      src={
                                        video.platform === "youtube"
                                          ? `https://img.youtube.com/vi/${
                                              video.vdo_id.split("?")[0]
                                            }/maxresdefault.jpg`
                                          : video.poster
                                      }
                                      className="w-[100px] h-40 object-cover rounded-md"
                                      alt=""
                                    />
                                    <div className="flex flex-col gap-2 flex-1">
                                      <p>
                                        {index + 1}. {video.name}
                                      </p>
                                      <p>{video.description}</p>

                                      {video.platform !== "youtube" && (
                                        <p>{video.duration}</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="above-icon flex w-[20px] justify-end">
                                    <FontAwesomeIcon
                                      icon={
                                        video.is_public === 1 ||
                                        LectureInfoSuccess?.data?.session_info
                                          ?.is_buy === 1
                                          ? faLockOpen
                                          : faUnlockKeyhole
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {/**
                       * //!---- PDFs -----
                       */}
                      {LectureInfoSuccess?.data?.attachments
                        ?.filter((video) => video.attachment_type === type)
                        .map((attachment, index) => {
                          return (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              href={
                                attachment.is_public === 1 ||
                                LectureInfoSuccess?.data?.session_info
                                  ?.is_buy === 1
                                  ? attachment.address
                                  : null
                              }
                              download={attachment.name}
                              rel="noreferrer"
                              target={
                                attachment.is_public === 1 ||
                                LectureInfoSuccess?.data?.session_info
                                  ?.is_buy === 1
                                  ? "_blank"
                                  : null
                              }
                              className={`single-attachment w-full  ${
                                attachment.is_public === 1 ||
                                LectureInfoSuccess?.data?.session_info
                                  ?.is_buy === 1
                                  ? "cursor-pointer "
                                  : " cursor-not-allowed"
                              }`}
                              key={attachment.key}
                            >
                              <div
                                className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                                  attachment.is_public === 1 ||
                                  LectureInfoSuccess?.data?.session_info
                                    ?.is_buy === 1
                                    ? ""
                                    : " pointer-events-none"
                                }
                          hover:bg-secondary hover:text-white`}
                              >
                                <div
                                  dir="ltr"
                                  className={`above flex w-full items-center justify-between gap-2`}
                                >
                                  <div className="above-title w-session flex items-center justify-start gap-7">
                                    <FontAwesomeIcon icon={faFileLines} />
                                    <p className="text- w-11/12 truncate hover:text-clip ">
                                      {index + 1}. {attachment.name}
                                    </p>
                                  </div>
                                  <div className="above-icon flex w-[20px] justify-end">
                                    <FontAwesomeIcon
                                      icon={
                                        attachment.is_public === 1 ||
                                        LectureInfoSuccess?.data?.session_info
                                          ?.is_buy === 1
                                          ? faLockOpen
                                          : faUnlockKeyhole
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })}
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="w-2/3 md:w-full">
            <div
              className={`
              flex video  width justify-end`}
            >
              <div
                className={`iframe-wrapper aspect-video overflow-hidden rounded-2xl  ${
                  state.currentVideo !== null && "border-secondary border-2 "
                } w-full`}
              >
                <VdoCiperrPlayer
                  videoId={state.currentVideo}
                  platform={state.platform}
                  // views={state.views}
                  history_id={LectureInfoSuccess?.data?.session_history?.key}
                  videoRef={videoRef}
                  videoContainerRef={videoContainerRef}
                  isAPIReady={isAPIReady}
                  setVideoRef={setVideoRef}
                />
                {state.platform !== "vdocipher" &&
                state.platform !== "bunny" ? (
                  <iframe
                    src={state.src}
                    width="100%"
                    height="100%"
                    title="YouTube video player"
                    //style={{ aspectRatio: "16 / 9" }}
                    allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    id="courseVideo"
                  ></iframe>
                ) : null}

                {state.platform === "bunny" && <BunnyVideo src={state.src} />}
              </div>
            </div>
            {LectureInfoSuccess?.data?.session_info.is_course !== 1 ? (
              <>
                {state.views && (
                  <p className="font-bold text-3xl mt-12 text-center">
                    {state.views === 0
                      ? "You have exceeded the viewing limit"
                      : `views: ${state.views}`}
                  </p>
                )}
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default StudentLecture;

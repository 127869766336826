//!visibility is available in edit only not in add
import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";

function AddEditLecture({ edit }) {
  const [values, setValues] = useState(null);
  const [content, setContent] = useState("");

  const { lectureID, chapterID } = useParams();
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditLecture": {
        return {
          ...state,
          submitAddEditLecture: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_LECTURE_API
    : process.env.REACT_APP_ADMIN_ADD_LECTURE_API;
  //!--------- add edit Lecture -------

  const [LectureAddEditSuccess, LectureAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditLecture.flag,
    state.submitAddEditLecture.dependency,
    state.submitAddEditLecture.data,
    true
  );

  //!--------- get the Lecture info for editing -------

  const [LectureInfo, LectureInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_LECTURE_INFO_API}${lectureID}`,
    "GET",
    lectureID,
    lectureID
  );

  const [LecturesData, LectureDataErrors] = useAxios(
    process.env.REACT_APP_ADMIN_GET_ALL_LECTURES_API,
    "GET",
    "GET",
    true
  );

  useEffect(() => {
    if (LectureInfo) {
      let temp = LectureInfo.data;
      delete temp.img;
      setContent(temp.description || "");
      setValues({
        ...temp,
        // next_lecture: temp.next_lecture,
        visibility: temp.visibility === 1 ? true : false,
        enable_buy: temp.enable_buy === 1 ? true : false,
      });
    }
  }, [LectureInfo]);

  useEffect(() => {
    //!---add actions ----

    if (LectureAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [LectureAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({ mode: "onTouched", values });

  const watchSequence = watch("seq");
  const watchStage = watch("stage");

  const onSubmit = (data) => {
    const finalData = chapterID
      ? {
          ...data,
          chapter_id: chapterID,
          description: content,
          img: data?.img[0] || null,
        }
      : lectureID
        ? {
            ...data,
            id: lectureID,
            img: data?.img[0] || null,
            description: content,
            visibility: data?.visibility === true ? 1 : 0,
            enable_buy: data?.enable_buy === true ? 1 : 0,
          }
        : {
            ...data,
            description: content,
            img: data?.img[0] || null,
          };
    dispatch({
      type: "setSubmitAddEditLecture",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditLecture.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Lectures | Nawar Academy"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {lectureID
            ? "Edit lecture data"
            : "Please fill in the information to add the lecture."}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50"
        >
          {/** Name stage filed */}
          <div className="flex w-full items-start justify-between gap-10 md:flex-col ">
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="title">Name</label>
              <input
                className="signin-inputs signin-input w-full pl-4    "
                type="text"
                id="title"
                placeholder="Name"
                name="title"
                autoComplete="on"
                {...register("title", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.title && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.title.type === "required" &&
                    "Please fill out this field"}
                  {errors.title.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.title.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.title.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.title && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.title[0]}
                    </p>
                  )
              }
            </div>

            <div
              className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="stage">
                Classroom
              </label>
              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>

              {errors.stage && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.stage.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.stage && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** price  days filed */}
          <div className="flex  w-full items-start justify-between gap-10 md:flex-col ">
            <div className=" price flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="price">
                Lecture price
              </label>
              <input
                className="signin-inputs signin-input w-full "
                type="number"
                min={0}
                id="price"
                placeholder="000"
                name="price"
                autoComplete="on"
                {...register("price", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.price && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.price.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.price && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.price[0]}
                    </p>
                  )
              }
            </div>

            <div className=" days flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="expired_at">
                Number of days
              </label>
              <input
                className="signin-inputs signin-input w-full "
                type="number"
                min={1}
                id="expired_at"
                placeholder="000"
                name="expired_at"
                autoComplete="on"
                {...register("expired_at", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.expired_at && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.expired_at.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.expired_at && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        LectureAddEditErrors?.response?.data?.errors
                          ?.expired_at[0]
                      }
                    </p>
                  )
              }
            </div>
          </div>
          {/* {edit && (
            <div className="flex  w-full items-start justify-between gap-10 md:flex-col ">
              <div className=" price flex w-full flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="final_steps">
                  Final steps
                </label>
                <select
                  name="final_steps"
                  id="final_steps"
                  {...register("final_steps", { required: true })}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>

                {errors.final_steps && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.final_steps.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {errors.final_steps && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.final_steps.type === "min" &&
                      "Please Add Only Positive Number"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  LectureAddEditErrors &&
                    LectureAddEditErrors?.response?.data?.errors
                      ?.final_steps && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          LectureAddEditErrors?.response?.data?.errors
                            ?.final_steps[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )} */}
          <div className="flex  w-full items-start justify-between gap-10 md:flex-col ">
            <div className=" price flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="seq">
                Sequance
              </label>
              <select
                name="seq"
                id="seq"
                {...register("seq", { required: true })}
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>

              {errors.seq && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.seq.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {errors.seq && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.seq.type === "min" &&
                    "Please Add Only Positive Number"}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.seq && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.seq[0]}
                    </p>
                  )
              }
            </div>
            {+watchSequence === 1 && LecturesData?.data ? (
              <div className=" price flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="next_lecture">
                  Lecture
                </label>
                <select
                  name="next_lecture"
                  id="next_lecture"
                  // defaultValue={}
                  defaultValue={"49"}
                  {...register("next_lecture", { required: true })}
                >
                  {LecturesData?.data
                    ?.filter((lecture) => lecture.stage === +watchStage)
                    .map((lecture) => (
                      <option value={lecture.key}>{lecture.title}</option>
                    ))}
                </select>

                {errors.next_lecture && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.next_lecture.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {errors.next_lecture && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.next_lecture.type === "min" &&
                      "Please Add Only Positive Number"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  LectureAddEditErrors &&
                    LectureAddEditErrors?.response?.data?.errors
                      ?.next_lecture && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          LectureAddEditErrors?.response?.data?.errors
                            ?.next_lecture[0]
                        }
                      </p>
                    )
                }
              </div>
            ) : null}
          </div>
          {/** visibility homework quiz fields */}
          {edit && (
            <div className="flex  w-full items-start gap-10 md:flex-col ">
              <div className="visibility flex w-1/3 flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div className="order-1">
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.visibility.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  LectureAddEditErrors &&
                    LectureAddEditErrors?.response?.data?.errors
                      ?.visibility && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          LectureAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
              <div className="visibility flex w-1/3 flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="enable_buy"
                      name="enable_buy"
                      {...register("enable_buy", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div className="order-1">
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="enable_buy"
                    >
                      Enable Buying
                    </label>
                  </div>
                </div>

                {errors.enable_buy && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.enable_buy.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  LectureAddEditErrors &&
                    LectureAddEditErrors?.response?.data?.errors
                      ?.enable_buy && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          LectureAddEditErrors?.response?.data?.errors
                            ?.enable_buy[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** Lecture Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full" htmlFor="img">
              Upload the lecture image
            </label>

            <input
              id="img"
              className="signin-inputs signin-input   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full  text-[12px] text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a picture of the lecture"}
                {errors.img.type === "validate" && "Maximum image size is 5MB"}
              </p>
            )}
            {
              //!-------server errors -----

              LectureAddEditErrors &&
                LectureAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {LectureAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description
            </label>
            <CKEditor
              config={{
                toolbar: {
                  items: [
                    "undo",
                    "redo",
                    "|",
                    "imageInsert",
                    "|",
                    "heading",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "link",
                    "blockQuote",
                    "removeFormat",
                    "|",
                    "selectAll",
                    "specialCharacters",
                    "superscript",
                    "subscript",
                    "style",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "|",
                    "fontFamily",
                    "fontColor",
                    "fontBackgroundColor",
                    "fontSize",
                    "highlight",
                    "|",
                    "horizontalLine",
                    "pageBreak",
                    "findAndReplace",
                    "restrictedEditingException",
                    "textPartLanguage",
                    "-",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                language: "en",
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "toggleImageCaption",
                    "imageStyle:inline",
                    "imageStyle:block",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableCellProperties",
                    "tableProperties",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={content}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              LectureAddEditErrors &&
                LectureAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {
                      LectureAddEditErrors?.response?.data?.errors
                        ?.description[0]
                    }
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditLecture.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditLecture.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{lectureID ? "Edit Lecture" : "Add Lecture"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditLecture;
